@font-face {
  font-family: "GT Haptik";
  font-weight: normal;
  font-weight: 400;
  font-style: normal;

  src: url("./fonts/GT-Haptik-Regular.eot") format("eot"),
    url("./fonts/GT-Haptik-Regular.woff") format("woff"),
    url("./fonts/GT-Haptik-Regular.woff2") format("woff2"),
    url("./fonts/GT-Haptik-Regular.ttf") format("ttf");
}

@font-face {
  font-family: "GT Haptik";
  font-weight: normal;
  font-weight: 400;
  font-style: italic;

  src: url("./fonts/GT-Haptik-Regular-Oblique.eot") format("eot"),
    url("./fonts/GT-Haptik-Regular-Oblique.woff") format("woff"),
    url("./fonts/GT-Haptik-Regular-Oblique.woff2") format("woff2"),
    url("./fonts/GT-Haptik-Regular-Oblique.ttf") format("ttf");
}

@font-face {
  font-family: "GT Haptik";
  font-weight: bold;
  font-weight: 700;
  font-style: normal;

  src: url("./fonts/GT-Haptik-Black.eot") format("eot"),
    url("./fonts/GT-Haptik-Black.woff") format("woff"),
    url("./fonts/GT-Haptik-Black.woff2") format("woff2"),
    url("./fonts/GT-Haptik-Black.ttf") format("ttf");
}

@font-face {
  font-family: "GT Haptik Rotalic";
  font-weight: normal;
  font-weight: 400;
  font-style: normal;

  src: url("./fonts/GT-Haptik-Regular-Rotalic.eot") format("eot"),
    url("./fonts/GT-Haptik-Regular-Rotalic.woff") format("woff"),
    url("./fonts/GT-Haptik-Regular-Rotalic.woff2") format("woff2"),
    url("./fonts/GT-Haptik-Regular-Rotalic.ttf") format("ttf");
}

@font-face {
  font-family: "GT Haptik Rotalic";
  font-weight: bold;
  font-weight: 700;
  font-style: normal;

  src: url("./fonts/GT-Haptik-Black-Rotalic.eot") format("eot"),
    url("./fonts/GT-Haptik-Black-Rotalic.woff") format("woff"),
    url("./fonts/GT-Haptik-Black-Rotalic.woff2") format("woff2"),
    url("./fonts/GT-Haptik-Black-Rotalic.ttf") format("ttf");
}

/* #Reset & Basics (Inspired by E. Meyers)
================================================== */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  font: inherit;
  font-size: 100%;

  margin: 0;
  padding: 0;

  vertical-align: baseline;

  border: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-spacing: 0;
  border-collapse: collapse;
}

/* #Basic Styles
================================================== */
* {
  box-sizing: border-box;
}

body {
  font-family: "GT Haptik", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  font-size: 1rem;
  font-weight: normal;
  font-weight: 400;
  line-height: 1.5;

  position: absolute;

  width: 100vw;
  min-height: 100vh;

  letter-spacing: 0.05em;

  color: #050608;
  background-color: #fff;
  background-image: url("/images/background-grid.svg");
  background-repeat: repeat;
  background-attachment: fixed;
  background-size: 13px;

  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}

/* #Typography
================================================== */
h1,
h2,
h3 {
  font-family: "GT Haptik", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  font-weight: bold;
  font-weight: 700;
  line-height: 1.1;

  letter-spacing: 0.075rem;
}

h1 {
  font-size: 2rem;

  margin: 1.5rem 0;
}

h1 a {
  padding: 0;

  border: none;
}

h2 {
  font-size: 1.35rem;

  margin: 0.5rem 0;
}

h3,
h3 a {
  font-size: 0.95rem;
  font-weight: 700;

  margin: 1rem 0 1rem;

  text-decoration: none;
  text-transform: uppercase;

  color: #5e96c6;
  border: none;
}

a {
  padding: 2px;

  text-decoration: none;

  color: #050608;
  border-bottom: 1px dashed #050608;
}

a:hover,
a:focus {
  transform: translateY(-2px);
  text-decoration: none;
}

a.no-transform:hover,
a.no-transform:focus {
  transform: translateY(0);
}

p {
  font-weight: normal;
  font-weight: 400;

  margin: 0.25rem 0 2rem;
}

p:last-of-type {
  margin-bottom: 0;
}

hr {
  width: 50%;
  margin: 2rem auto;

  opacity: 0.25;
  border: 0;
  border-top: 1px solid #fff;
}

.flex {
  display: flex;
}
.flex-row {
  flex: row wrap;
}

.flex-center {
  align-items: center;
}

.center {
  text-align: center;
}
.block {
  display: block;
}
.inline {
  display: inline;
}
.inline-block {
  display: inline-block;
}
.hidden {
  display: none;
}

.m-0 {
  margin: 0;
}

.box-shadow {
  box-shadow: 0 2px 10px 3px rgba(5%, 6%, 8%, 0.2);
}

em {
  font-style: italic;
}
strong,
.bold {
  font-weight: bold;
  font-weight: 700;
}
.underline {
  text-decoration: underline;
}

ul {
  margin: 0.25rem 2rem 3rem;

  list-style: square outside none;
}
ol {
  margin: 0.25rem 2rem 3rem;

  list-style: decimal outside none;
}
li {
  margin: 0.5rem 0;
}
ul.inline li {
  display: inline;
}

.p-1 {
  padding: 0.5rem;
}

img {
  max-width: 100%;
  height: auto;
}

svg {
  overflow: visible;

  max-width: 100%;
  height: auto;

  transform-origin: center;
}

svg path {
  transform-origin: center;
}

//
// MAIN CONTENT
//

.box-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  width: 100vw;
  max-width: 960px;
  margin: 2rem auto;
}

.main {
  width: 100%;
  max-width: 540px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  margin: 0 auto 2rem;
  padding: 2rem;

  background-color: #fff;
}

.talk-show {

  h1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    font-size: 1rem;
    letter-spacing: -.03rem;
    font-weight: 500;
    margin: .5rem auto 2rem;

    strong {
      font-size: 2.25rem;
      font-style: italic;
      color: #e85c3d;
      margin-top: 2px;
      display: block;
    }

    svg {
      width: 3rem;
      margin: 0;
      margin-right: 1rem;
    }
  }

  iframe {
    width: 100%;
    height: 300px;
    margin: 0 0 2rem;
  }

  iframe#twitch-embed {
    height: 500px;
  }

  p {
    max-width: 360px;
    font-size: .8rem;
    margin: 0 auto 1rem;
  }
}

.values {
  border: 4px solid #050608;
}

.value-link {
  line-height: 1.35;

  display: inline-block;

  padding: 3px 6px;

  transition: opacity 125ms ease-in-out, transform 125ms ease-in-out;

  color: #fff;
  border: none;
  border-radius: 4px;
  background-color: #050608;
}

.value-link:hover,
.value-link:focus {
  transform: translateY(-2px);

  opacity: 0.75;
}

.black-block {
  color: #fff;
  background-color: #050608;
}

.black-block ul {
  margin: 0;

  list-style: none;
}

.about {
  // width: 380px;
  // margin-right: 40px;
}

@media (max-width: 960px) {
  .box-wrapper {
    flex-direction: column;
  }

  .about {
    order: 2;

    width: 100%;
    margin-top: 2rem;
    margin-right: inherit;
  }
}

//
// SECTIONS
//

header {
  margin-bottom: 1rem;
}

header .logo {
  display: inline-block;

  padding: 0;

  text-decoration: none;

  border: none !important;
}

header svg {
  display: inline;
}

header ul {
  float: right;
}

.vertical-center {
  position: absolute;
  top: 50%;
  left: 50%;

  width: 100%;
  max-width: 540px;
  padding: 1em;

  transform: translate(-50%, -50%);
  text-align: center;
}

@-webkit-keyframes fadeIn {
  0% {
    transform: translateY(2em);

    opacity: 0;
  }
  100% {
    transform: translateY(0px);

    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    transform: translateY(2em);

    opacity: 0;
  }
  100% {
    transform: translateY(0px);

    opacity: 1;
  }
}

.logo-text,
.logo-ramen {
  position: relative;

  width: auto;
  height: 3rem;
  min-height: 2rem;
  max-height: 6rem;
  margin: 0 auto 1rem;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.logo-ramen path {
  // fill: #050608;
}

.logo-text {
  cursor: -webkit-grab;
  cursor: grab;
}

.avatar {
  display: inline-block;

  padding: 0 1rem;

  transition: opacity 125ms ease-in-out, transform 125ms ease-in-out;
  vertical-align: middle;
  text-decoration: none;

  border: none;
  background: transparent;
}

.avatar img {
  display: block;

  width: auto;
  max-height: 80px;
  margin: 0;
}

.avatar:hover,
.avatar:active {
  transform: translateY(-2px);

  opacity: 0.85;
}

@media (max-width: 740px) {
  .avatar img {
    margin: 0 auto;
  }
}

#email-address {
  display: block;

  text-decoration: underline;

  opacity: 0.65;
}

small,
.small {
  font-size: 0.75em;
  font-style: italic;
  line-height: 1.65;
  // opacity: .5;
}

::-moz-selection {
  color: #050608;
  background: #f6c452;
}

::selection {
  color: #050608;
  background: #f6c452;
}

::-moz-selection {
  color: #050608;
  background: #f6c452;
}

/*
  Inputs
*/

input {
  font-family: "GT Haptik", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  font-size: 1rem;
  font-weight: normal;
  font-weight: 400;
  line-height: 1.65;

  padding: 0.5rem 1rem;

  letter-spacing: 0.065rem;

  color: #222;
  border: 2px solid #222;
  border-radius: 0;
  background: #fff;
  background: #222;

  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
}

input:focus,
input:hover,
input:active {
  border-color: aliceblue;
}

::-webkit-input-placeholder {
  font-family: "GT Haptik", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica,
    Arial, sans-serif;

  color: rgba(255, 255, 255, 0.5);
}
::-moz-placeholder {
  /* Firefox 19+ */
  font-family: "GT Haptik", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica,
    Arial, sans-serif;

  color: rgba(255, 255, 255, 0.5);
}
:-ms-input-placeholder {
  font-family: "GT Haptik", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica,
    Arial, sans-serif;

  color: rgba(255, 255, 255, 0.5);
}
:-moz-placeholder {
  /* Firefox 18- */
  font-family: "GT Haptik", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica,
    Arial, sans-serif;

  color: rgba(255, 255, 255, 0.5);
}
